import React, { useEffect } from 'react'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

const report = '/docs/Entregador-3-versao.pdf'

const PrivacyCouriersPDF = () => {
  useEffect(() => {
    if (window) {
      window.location = report
    }
  })
  return (
    <Layout>
      <SEO
        description="Privacidade entregador PDF válido de 18/08/2022 até 13/07/2023"
        title="Privacidade entregador PDF"
      />
    </Layout>
  )
}

export default PrivacyCouriersPDF
